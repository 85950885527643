import { SessionUser } from 'next-auth';
import * as yup from 'yup';

const tokenSchema = yup.object({
    id: yup.string().required(),
    email: yup.string().required(),
    name: yup.string().nullable().required(),
    sessionToken: yup.string().required(),
    authProvider: yup.string().required(),
    // Optional User Data
    firstname: yup.string().nullable().optional(),
    lastname: yup.string().nullable().optional(),
    lastLogin: yup.number().optional().nullable(),
    phoneNumber: yup.string().optional().nullable(),
    image: yup.string().nullable().optional(),
    company: yup
        .object({ id: yup.number().optional().nullable(), name: yup.string().optional().nullable() })
        .optional(),
    customerId: yup.string().optional().nullable(),
    subscription: yup.string().optional(),
    subscriptionStatus: yup.string().optional(),
    colorTheme: yup.string().optional().nullable().oneOf(['Dark', 'Light', 'System']),
});

const apiError = yup.object({
    error: yup.string().equals(['Session Error']).required(),
});

const validateToken = (session: SessionUser | null) => {
    // Apply coercions to schema - but do not error out of session is not completely valid
    // Data missing at this stage is unlikely to produce runtime errors but should still be reported in logs
    const parsed = tokenSchema.cast(session, { assert: false });
    try {
        tokenSchema.validateSync(parsed);
    } catch (err) {
        console.error(`Error validating token: ${err}`);
    }
    return parsed as SessionUser;
};

export { apiError, tokenSchema, validateToken };
