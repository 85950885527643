import { PlatformConfig } from '../../config';

const BASE_URLS = {
    'same-origin': '',
    beehiiv: 'https://api.beehiiv.com/v2/publications',
    'blockworks.co': 'https://blockworks.co',
    coingecko: 'https://api.coingecko.com/api/v3',
    'coingecko-pro': 'https://pro-api.coingecko.com/api/v3',
    'coin-market-cap': 'https://api.coinmarketcap.com/data-api/v3',
    'research-api': process.env.API_ENDPOINT ?? process.env.RESEARCH_API_URL,
    strapi: PlatformConfig.STRAPI_API_URL,
    'strapi-proxy': '/api/strapi',
    wordpress: 'https://cms.blockworks.co/wp-json',
    mapbox: 'https://api.mapbox.com',
    grip: 'https://api.grip.events/1',
    slack: 'https://slack.com',
} as const;

type BaseUrl = keyof typeof BASE_URLS;

export type { BaseUrl };
export { BASE_URLS };
