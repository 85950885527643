import { useSearchBox } from '@blockworks/platform/libs/react-instantsearch';
import { FlexBox, List } from '@blockworks/ui/components';

import { AssetItem } from './hit-items/asset-item';
import { AuthorItem } from './hit-items/author-item';
import { DashboardItem } from './hit-items/dashboard-item';
import { ProposalItem } from './hit-items/proposal-item';
import { ResearchItem } from './hit-items/research-item';
import { ItemTypes } from './utils/search.enums';
import { AssetHit, AuthorHit, DashboardHit, ProposalHit, RecentlyViewedItem, ResearchHit } from './utils/search.types';
import { getRecentlyViewedHits } from './utils/search.utils';
import { ResultsSectionHeader } from './result-section-header';

const ItemComponent = (recentItems: RecentlyViewedItem) => {
    switch (recentItems.type) {
        case ItemTypes.Proposal:
            return <ProposalItem {...(recentItems.hit as ProposalHit)} />;
        case ItemTypes.Dashboard:
            return <DashboardItem {...(recentItems.hit as DashboardHit)} />;
        case ItemTypes.Asset:
            return <AssetItem {...(recentItems.hit as AssetHit)} />;
        case ItemTypes.Author:
            return <AuthorItem {...(recentItems.hit as AuthorHit)} />;
        case ItemTypes.Research:
            return <ResearchItem {...(recentItems.hit as ResearchHit)} />;
        default:
            return null;
    }
};

export const RecentlyViewedSection = () => {
    const recentHits = getRecentlyViewedHits();
    const { query } = useSearchBox();

    if (query?.length > 0) {
        return null;
    }

    return (
        <FlexBox col border="divide-y">
            <ResultsSectionHeader title={'Recently Viewed'} />
            <List variant="flat" as="ul" name="search-results">
                {recentHits.map(item => (
                    <ItemComponent key={item.hit.objectID} {...item} />
                ))}
            </List>
        </FlexBox>
    );
};
