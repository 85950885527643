import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const listContainerStyles = tVariants({
    base: ['overflow-hidden', 'flex flex-col'],
    variants: {
        ...factoryStyleProps.border,
        noContainerRadius: { true: '!rounded-none' },
        bgColor: factoryStyleProps.color.bgColor,
    },
});

const listStyles = tVariants({
    base: ['group/list w-full p-0 first-line:rounded-md text-xs h-full relative z-0'],
    variants: {
        divided: { true: 'divided rounded-md divide-y divide-surface-divider' },
        variant: {
            compact:
                'compact flex flex-col border border-surface-divider bg-surface rounded-md [--col-padding-x:0.25rem] [--col-padding-y:0px] [--row-interact:--color-surface-muted]',
            'compact-alt': [
                'compact-alt flex flex-col bg-surface',
                '[--col-padding-x:0.25rem] [--col-padding-y:0px] [--row-interact:--color-surface-muted]',
                '!rounded-none first-line:!rounded-none last-line:!rounded-none [&>*:nth-child(2)]:!border-t-0 [&:last-child]:border-b',
            ].join(' '),
            adjacent:
                'loose rounded-md border border-surface-divider bg-surface [--col-padding-x:0.25rem] [--col-padding-y:0.25rem] [--row-interact:--color-surface-muted]',
            'adjacent-transparent':
                'loose rounded-md border divide-y border-surface-divider bg-transparent[--col-padding-x:0.5rem] [--col-padding-y:0.5rem] [--row-interact:--color-surface-muted]',
            spaced: 'spaced raised flex flex-col gap-2 [--col-padding-x:0.25rem] [--col-padding-y:0.25rem] [--row-interact:--color-surface-muted]',
            flat: 'spaced flex flex-col [--col-padding-x:0.25rem] [--col-padding-y:0.25rem] [--row-interact:--color-surface-raised]',
        },
        ...factoryStyleProps.__atoms.space.padding,
        ...factoryStyleProps.border,
        bgColor: factoryStyleProps.color.bgColor,
    },
});

const { flexGrow, flexShrink, ...originalFlexProps } = factoryStyleProps.__atoms.layout.flex;

const listRowStyles = tVariants({
    base: [
        'flex max-w-full overflow-hidden items-stretch w-full first:rounded-t-md last:rounded-b-md aria-selected:bg-surface-divider aria-selected:border-surface-selected px-2 focus:bg-surface-divider',
    ],
    variants: {
        hoverable: {
            true: 'transition-colors duration-0 hover:bg-current-bg hover:[--color-current-bg:--row-interact] group-[.raised]/list:hover:[--color-current-bg:--row-interact]',
        },
        clickable: {
            true: 'transition-colors duration-0 clickable cursor-pointer hover:bg-current-bg hover:[--color-current-bg:--row-interact] group-[.raised]/list:hover:[--color-current-bg:--row-interact]',
        },
        type: {
            item: 'group-[.spaced]/list:py-2 group-[.spaced]/list:p-2 group-[.raised]/list:bg-current-bg  group-[.raised]/list:[--color-current-bg:--color-surface]  group-[.raised]/list:border border-surface-divider bg-current-bg rounded-sm min-h-[--row-height]',
            header: 'bg-surface-muted text-content-deselected py-0.5 sticky top-0 z-10',
            'header-alt': [
                'bw-header-alt',
                'after:content-[""] after:block after:w-full after:h-[1.5px] after:bg-content-highlight after:absolute after:bottom-0 after:left-0',
                'text-content-highlight bg-current-bg',
                'font-semibold',
                'pt-0 pb-1 sticky top-0 z-10',
            ].join(' '),
        },
        grow: { true: 'grow' },
        shrink: { true: 'shrink' },
        bgColor: factoryStyleProps.color.bgColor,
        ...originalFlexProps,
        ...factoryStyleProps.size,
        ...factoryStyleProps.space,
    },
});

const listColStyles = tVariants({
    base: [
        'max-w-full flex items-center flex-none px-[--col-padding-x] py-[--col-padding-y] group-[.compact]/list:py-0',
    ],
    variants: {
        size: {
            '7xs': 'w-4',
            '6xs': 'w-8',
            '5xs': 'w-12',
            '4xs': 'w-16',
            '3xs': 'w-20',
            '2xs': 'w-28',
            xs: 'w-36',
            sm: 'w-52',
            md: 'w-56',
            lg: 'w-64',
            xl: 'w-72',
            '1/4': 'w-1/4',
            '1/8': 'w-1/8',
            '7/8': 'w-7/8',
            '1/2': 'w-1/2',
            '3/4': 'w-3/4',
            '4/5': 'w-4/5',
            '2xl': 'w-80',
            '3xl': 'w-96',
            '4xl': 'w-112',
            /** finds the largest flex item (or cell) in a column and stretches the width of corresponding columns to that width */
            fit: 'min-w-0 flex-[1]',
            fill: 'w-min flex-auto',
            content: 'w-fit',
            auto: 'w-auto',
        },
        flush: {
            right: 'pr-0',
            left: 'pl-0',
            bot: 'px-0',
        },
        xRightSpace: { true: 'mr-4 last:mr-0' },
        noWrap: { true: 'whitespace-nowrap' },
        left: { true: 'justify-start' },
        right: { true: 'justify-end' },
        center: { true: 'justify-center' },
        grow: { true: 'grow' },
        shrink: { true: 'shrink' },
        ...originalFlexProps,
    },
});

export { listColStyles, listContainerStyles, listRowStyles, listStyles };
